<template>
<div class="notice">
    <van-tabs v-model="active" border title-active-color="#387ff5" color="#387ff5" line-height="1px" @click="getDataList" @change="tabChange(active)">
      <van-tab title="待回复" name="0" type="line" style="background-color: #F5F5F5;"  >
        <div class="cont">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
            <van-cell v-for="item in dataList" :key="item.id"  @click="toDetail(item.id, item.checkStatus)">
              <div class="item">
                <van-row>
                  <van-col :span="20">
                    <p>{{item.letterTitle}}</p>
                  </van-col>
                  <van-col :span="4">
                    <span style="color: #E61313;">待回复</span>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col :span="22">
                    <span>{{item.createTime}}</span>
                  </van-col>
                  <van-col :span="2">
                    <van-icon name="arrow" />
                  </van-col>
                </van-row>
              </div>
            </van-cell>
          </van-list>
          <van-empty description="没有数据哦"  v-else/>
        </div>
      </van-tab>

      <van-tab title="已回复" name="3" type="line">
        <div class="cont">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
            <van-cell v-for="item in dataList" :key="item.id"  @click="toDetail(item.id, item.checkStatus)">
              <div class="item">
                <van-row>
                  <van-col :span="20">
                    <p>{{item.letterTitle}}</p>
                  </van-col>
                  <van-col :span="4">
                    <span style="color: #1E7DFF;">已回复</span>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col :span="22">
                    <span>{{item.createTime}}</span>
                  </van-col>
                  <van-col :span="2">
                    <van-icon name="arrow" />
                  </van-col>
                </van-row>
              </div>
            </van-cell>
          </van-list>
          <van-empty description="没有数据哦"  v-else/>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
export default {
  components :{
    topBar
  },
  name: "peopleMail",
  data() {
    return {
      page: 0,
      limit: 10,
      isLoading:false,
      loading: false,
      finished:false,
      checkStatus: 0,
      active: 0,
      dataList:[]
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    tabChange (name) {
      this.dataList = []
      this.page=0
      this.finished = false
      this.checkStatus = Number(name)
      this.getDataList()
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/future/letter/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          orgId: this.$orgId,
          checkStatus: this.checkStatus,
          lookPublic: 1,
          proposedTarget: this.$globalData.userInfo.userId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
      // 数据全部加载完成
    },
    toDetail(id, checkStatus) {
      this.$router.push({path: '/peopleMail-info', query: {id: id, checkStatus:checkStatus}})
    }
  }
}
</script>

<style scoped lang="scss" >
.notice {
  padding-top: 100px;
  min-height: 100vh;
  background-color: #f5f5f5;
  .cont {
    background-color: #f5f5f5;
    .van-cell {
      padding: 15px 15px;
    }
    .item {
      .van-row:nth-child(1) .van-col:nth-child(2){
        margin-top: 10px;
        padding-left: 22px;
      }
      .van-row:nth-child(2) .van-col:nth-child(2){
        padding-left: 22px;
      }
      p {
        margin: 10px 0;
        font-size: 30px;
        font-weight: 600;
      }
      span {
        font-size: 26px;
      }
    }
  }
}
</style>
